import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/helpers/auth.guard';
import { GroupInvitationExpiredComponent, UnderConstructionComponent, ValidateInvitationComponent } from './shared/components';

const routes: Routes = [
  //{ path: '', pathMatch: 'full', redirectTo: '/account' },
  // { path: 'welcome', loadChildren: () => import('./pages/welcome/welcome.module').then(m => m.WelcomeModule) }
  { path: 'account', loadChildren: () => import('./account/account.module').then(m => m.AccountModule) },
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () => import('./user/user.module').then(m => m.UserModule)
  },
  { path: 'under-construction', component: UnderConstructionComponent},
  { path: 'invitation-expired', component: GroupInvitationExpiredComponent},
  { path: 'validate-invitation', component: ValidateInvitationComponent }


];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
